import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as yup from "yup";

const Year = (props) => {
  const [error, setError] = useState(null);
  let schema = yup
    .number()
    .integer("Please enter a valid year only")
    .min(1900, "Please enter a valid year only");

  const updateText = (e) => {
    setError(null);
    let res = "";
    try {
      res = schema.validateSync(e.target.value);
      props.onAnswer(res.toString());
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col>
          <Form.Control
            onChange={updateText}
            type="text"
            required={props.data.required}
            defaultValue={props.data.answer}
          />
          {error !== null && (
            <div style={{ color: "red", fontSize: "0.8em", marginTop: "4px" }}>
              {error}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Year;
