import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

const Checkbox = (props) => {
  const [currentAnswer, setCurrentAnswer] = useState(false);

  useEffect(() => {
    if (props.data.answer !== undefined && props.data.answer !== null) {
      setCurrentAnswer(props.data.answer);
    } else {
      setCurrentAnswer(false);
    }
  }, [props.data.answer]);

  const onClick = (e) => {
    let ans = e.target.checked;
    setCurrentAnswer(ans);
    if (props.data.required && !ans) {
      props.onAnswer(null);
    } else {
      props.onAnswer(ans);
    }
  };

  return (
    <div>
      <Form.Check
        required={props.data.required}
        label={props.data.label}
        type="checkbox"
        checked={currentAnswer}
        onChange={onClick}
      />
    </div>
  );
};

export default Checkbox;
