var country_list = [
  "United States of America (the)",
  "Canada",
  //"Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  //"Burundi",
  "Cabo Verde",
  //"Cambodia",
  "Cameroon",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  //"Congo (the Democratic Republic of the)",
  //"Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  //"Guinea-Bissau",
  "Guyana",
  //"Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  //"Iran (Islamic Republic of)",
  //"Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  //"Lebanon",
  "Lesotho",
  "Liberia",
  //"Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  //"Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  //"Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  //"Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  // "South Sudan",
  "Spain",
  "Sri Lanka",
  //"Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  //"Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  //"Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  //"Yemen",
  "Zambia",
  //"Zimbabwe",
  "Åland Islands",
];

const QuestionsValues = [
  // Question Format:
  // {
  //    questions: [{
  //      id: 1,
  //      question: "What is your field of business/trade?",
  //      type: "single", //[text|single|number|date|email|password|multiple|month|year|textarea|file]
  //      options: ['Option 1', 'Option 2', 'Option 3'],
  //      hasOthers: true, //true=allow 'other' option and add text box
  //      answer: null,
  //      required: true,
  //      showIf: { id: id of question in the same list, condition: [equal|no-equal], value : value of question },
  //      copyFrom : { pageTitle : '', id : [array of questionID] }
  //  },],
  // title: "", description:""
  // }

  {
    title: "Your Information",
    description: "",
    questions: [
      { id: 1, question: "First Name", type: "TEXT", required: true },
      { id: 2, question: "Last Name", type: "TEXT", required: true },
      { id: 3, question: "Title Name", type: "TEXT", required: true },
      { id: 4, question: "Email", type: "EMAIL", required: true },
    ],
  },

  {
    title: "Type of Listing",
    description: `<p>
        Are you a private company looking to list your own securities
        <br />
        or are you an intermediary/platform that would have numerous listings (SPVs, Funds, etc.)?
      </p>`,
    questions: [
      {
        id: 1,
        question: "We are ...",
        type: "SINGLE",
        options: ["A private company", "An intermediary/platform", "Both"],
        required: true,
      },
    ],
  },

  {
    title: "Retail Investors",
    description: `<p>
        Do you require access to non-accredited investors?
      </p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
    ],
  },

  {
    title: "US Investors",
    description: `<p>Do you require access to US investors?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
    ],
  },

  {
    title: "Fundraising",
    description: `<p>
        Are you looking to raise capital during this initial listing or only secondary trading?
      </p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Raise capital", "Only secondary trading"],
        required: true,
      },
    ],
  },

  {
    title: "Financials",
    description: `<p>Do you have at least one year of audited financial statements?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: false,
      },
    ],
  },

  {
    title: "Multiple Venues",
    description: `<p>Are you considering listing on multiple trading venues?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
    ],
  },

  {
    title: "Readiness",
    description: `<p>How ready are you to list on a stock exchange?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Ready to list", "Considering", "Just learning"],
        required: true,
      },
    ],
  },

  {
    title: "Objective",
    description: `<p>What is your main objective for a listing?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "TEXTAREA",
        maxLength: 280,
        required: true,
      },
    ],
  },
  // ],

  // PART II
  // [
  {
    title: "Country of issuer",
    description: `<p>What is the country of incorporation for the entity you are listing?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: country_list,
        required: true,
        hasOthers: true,
      },
    ],
  },

  {
    title: "Date of Founding",
    description: `<p>What year was the company founded?</p>`,
    questions: [{ id: 1, question: "", type: "YEAR", required: true }],
  },

  {
    title: "Type of Security",
    description: `<p>What are you interested in listing? <em>(check all that apply)</em></p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "MULTIPLE",
        options: [
          "Equity",
          "Debt",
          "Fund",
          "Derivative",
          "Asset-backed",
          "Don't know yet",
        ],
        required: true,
      },
    ],
  },

  {
    title: "Category of Security",
    description: `<p>In which industry and/or asset class is your security?</p>`,
    questions: [{ id: 1, question: "", type: "TEXT", required: true }],
  },

  {
    title: "Number of Listings",
    description: `<p>How many listings do you expect to complete in any given year?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["1-2", "3-5", "10-20", "20+"],
        required: true,
      },
    ],
  },

  {
    title: "Size of Company",
    description: `<p>Market capitalization or Pre-money valuation of last/current round.</p>`,
    questions: [
      {
        id: 1,
        question: "Amount",
        type: "NUMBER",
        required: true,
        format: "MONEY",
      },
      { id: 2, question: "Description", type: "TEXT", required: true },
    ],
  },

  {
    title: "Target Regions",
    description: `<p>Please check all regions that apply for investors you are targeting</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "MULTIPLE",
        options: [
          "Global",
          "US",
          "Canada",
          "South America",
          "Asia",
          "EU",
          "Africa",
        ],
        required: true,
      },
      /* {
        id: 2,
        question: "Comment",
        type: "TEXTAREA",
        maxLength: 280,
        required: false,
    },*/
    ],
  },

  {
    title: "Offering Document",
    description: `<p>Do you have a prior offering document (e.g. OM, PPM, Prospectus, etc.) that can be referenced for purposes of the listing documentation?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 2,
        question: "Approximate Year",
        type: "YEAR",
        required: true,
        showIf: { id: 1, condition: "equal", value: "Yes" },
      },
      {
        id: 3,
        question: "Approximate Month",
        type: "MONTH",
        required: true,
        showIf: { id: 1, condition: "equal", value: "Yes" },
      },
      {
        id: 4,
        question: "Upload document",
        type: "FILE",
        required: false,
        allowed: ["pdf", "doc", "docx"],
        showIf: { id: 1, condition: "equal", value: "Yes" },
      },
    ],
  },

  {
    title: "Dataroom",
    description: `<p>Do you have an organized dataroom of corporate documents for entity and security you are looking to list for investor diligence KYB/KYC purposes?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
    ],
  },

  {
    title: "Cap Table",
    description: `<p>Do you have a cap table updated as of today for the security you are looking to list?<br />How many shareholders do you have today?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 2,
        question:
          "Approximate number of current investors/shareholders of security today?",
        type: "SINGLE",
        options: ["<20", "20-50", "50+"],
        required: true,
      },
    ],
  },

  {
    title: "Transfer Restrictions",
    description: `<p>Are there current transfer restrictions on the security you are looking to list?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["None", "Reg D", "Private placement", "Let's discuss later"],
        required: true,
      },
      { id: 2, question: "Description", type: "TEXT", required: false },
    ],
  },

  {
    title: "Last Fundraising",
    description: `<p>When was your last fundraising round?<br/>How much have you raised cumulatively?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: [
          "never",
          "within last 12 months",
          "12-24 months",
          "beyond 24 months",
        ],
        required: true,
      },
      {
        id: 2,
        question: "Total raised",
        type: "NUMBER",
        format: "MONEY",
        required: true,
        showIf: {
          id: 1,
          condition: "not-equal",
          value: "never",
        },
      },
    ],
  },

  {
    title: "Financials",
    description: `<p>Please provide a bit more financial information about your company.</p>`,
    questions: [
      {
        id: 1,
        question: "Do you have updated and recent financials?",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 2,
        question: "What is your last 12 months revenue?",
        type: "NUMBER",
        required: true,
        format: "MONEY",
      },
      {
        id: 3,
        question: "Do you have a projection model?",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 4,
        question: "Are you profitable?",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 5,
        question: "Do you offer a dividend?",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 6,
        question: "If so, is there a dividend in the next 12 months?",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 7,
        question: "How much cash runway do you have?",
        type: "SINGLE",
        options: ["< 6 months", "6-12 months", "12 months+"],
        required: true,
      },
    ],
  },

  {
    title: "Market Opportunity",
    description: `<p>How do you describe your value proposition to investors?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "TEXTAREA",
        maxLength: 280,
        required: true,
      },
    ],
  },

  {
    title: "Governance",
    description: `<p>How many board members do you have?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: [
          "Not applicable",
          /* "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",*/
          "Subsidiary",
          "Type the number"
        ],
        required: true,
      },
      {
        id: 2,
        question: "Type the number",
        type: "NUMBER",
        required: true,
        showIf: {
          id: 1,
          condition: "equal",
          value: "Type the number",
        },
      },
    ],
  },

  {
    title: "Tokenized",
    description: `<p>Are the securities tokenized now?  If so, what chain?</p>`,
    questions: [
      {
        id: 1,
        question: "Tokenized",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
      {
        id: 2,
        question: "Chain",
        type: "SINGLE",
        options: ["Liquid Network", "Ethereum"],
        hasOthers: true,
        required: true,
        showIf: { id: 1, condition: "equal", value: "Yes" },
      },
    ],
  },

  {
    title: "Legal Support",
    description: `<p>Will your legal counsel be available as needed?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "SINGLE",
        options: ["Yes", "No"],
        required: true,
      },
    ],
  },

  {
    title: "Key Contact",
    description: `<p>Who would be the point person at your firm moving forward?</p>`,
    questions: [
      {
        id: 1,
        question: "",
        type: "CHECKBOX",
        label: "Same person who filled in this assessment",
        answer: false,
      },
      {
        id: 2,
        question: "Name",
        type: "TEXT",
        required: true,
        showIf: { id: 1, condition: "equal", value: false },
      },
      {
        id: 3,
        question: "Title",
        type: "TEXT",
        required: true,
        showIf: { id: 1, condition: "equal", value: false },
      },
      {
        id: 4,
        question: "Email",
        type: "EMAIL",
        required: true,
        showIf: { id: 1, condition: "equal", value: false },
      },
      {
        id: 5,
        question: "Name",
        type: "TEXT",
        required: true,
        showIf: { id: 1, condition: "equal", value: true },
        copyFrom: { pageTitle: "Your Information", id: [1, 2] },
        disabled: true,
      },
      {
        id: 6,
        question: "Title",
        type: "TEXT",
        required: true,
        showIf: { id: 1, condition: "equal", value: true },
        copyFrom: { pageTitle: "Your Information", id: [3] },
        disabled: true,
      },
      {
        id: 7,
        question: "Email",
        type: "EMAIL",
        required: true,
        showIf: { id: 1, condition: "equal", value: true },
        copyFrom: { pageTitle: "Your Information", id: [4] },
        disabled: true,
      },
    ],
  },

  {
    title: "Marketing Materials (Recommended)",
    description: `<p>Overview materials upload</p>`,
    questions: [
      {
        id: 1,
        question: "Investor deck",
        type: "FILE",
        allowed: ["pdf", "doc", "docx"],
        required: false,
      },
      {
        id: 2,
        question: "Summary document",
        type: "FILE",
        allowed: ["pdf", "doc", "docx"],
        required: false,
      },
    ],
  },

  //not used for now (not creating account yet)
  // {
  //   title: "Finish creating your account",
  //   description: "",
  //   questions: [
  //     { id: 1, question: "Password", type: "PASSWORD", required: true },
  //     {
  //       id: 2,
  //       question: "Confirm Password",
  //       type: "PASSWORD",
  //       required: true,
  //       confirmWith: 1, //id of confirming field
  //     },
  //   ],
  //   required: true,
  // },
];

export default QuestionsValues;
