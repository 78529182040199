import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";

/**
 * final thank you message at the end of the full assessment
 * @param {*} props
 */
const Thankyou = (props) => {
  return (
    <Container>
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col>
          <ProgressBar variant="success" striped animated now={100} />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h3 className="my-5">
            Thank you for completing the full assessment!
            <br />
            We will get in touch with you as soon as possible
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Thankyou;
