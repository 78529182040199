import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import QuestionItem from "./QuestionItems/QuestionItem";

// check if a question should be visible or not (based on showIf condition)
const shouldShowQuestion = (question, questions) => {
  //parse condition
  let qCheck = question;
  for (let k in questions) {
    if (question.id === questions[k].id) continue;
    if (questions[k].id === qCheck.showIf.id) {
      let answer = "";
      if (questions[k].answer === undefined || questions[k].answer === null) {
        answer = null;
      } else if (typeof questions[k].answer === "string") {
        answer = questions[k].answer.trim();
      } else {
        answer = questions[k].answer;
      }

      if (qCheck.showIf.condition === "equal") {
        if (
          typeof qCheck.showIf.value === "object" &&
          qCheck.showIf.value.indexOf(answer) > -1
        ) {
          return true;
        } else if (qCheck.showIf.value === answer) {
          return true;
        }
        return false;
      } else if (qCheck.showIf.condition === "not-equal") {
        if (
          typeof qCheck.showIf.value === "object" &&
          qCheck.showIf.value.indexOf(answer) === -1
        ) {
          return true;
        } else if (qCheck.showIf.value !== answer && answer !== null) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    }
  }
  return false;
};

const ProcessVisibles = (questions) => {
  let visibles = [];
  for (let i in questions) {
    if (questions[i].showIf === undefined) {
      visibles[i] = true;
    } else {
      visibles[i] = shouldShowQuestion(questions[i], questions);
    }
  }
  return visibles;
};

/**
 * main quesiton page
 * @param {*} props
 */
const Questions = (props) => {
  const [page, setPage] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [files, setFiles] = useState({});
  const [isVisible, setIsVisible] = useState({});

  const onSubmit = (event) => {
    setValidated(false);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      for (let i = 0; i < form.length; i++) {
        const elem = form[i];
        const errorLabel = elem.parentNode.querySelector(".invalid-feedback");
        if (errorLabel && elem.nodeName.toLowerCase() !== "button") {
          if (!elem.validity.valid) {
            errorLabel.textContent = elem.validationMessage;
            errorLabel.style = "display: block;";
          } else {
            errorLabel.textContent = "";
            errorLabel.style = "display: none;";
          }
        }
      }
      event.preventDefault();
      // event.stopPropagation();
      setError("Please complete all required fields/questions.");
      return false;
    }
    event.preventDefault();
    // setValidated(true);
    return onNext();
  };

  const onNext = () => {
    setError(null);
    //validate then move forward
    if (!isValid(page)) {
      if (passwordMismatch) {
        setError("Password mismatch.");
      } else {
        setError("Please complete all required fields/questions.");
      }
      return false;
    }

    if (page < questions.length - 1) {
      setPage(page + 1);
      setValidated(false);
    } else {
      //send answers along with questions to parent for processing
      props.onSubmit(questions, files);
    }
    return false;
  };

  const onAnswer = (question, answer) => {
    let f = files;
    //store answer
    let q = questions;
    for (var i in q[page].questions) {
      if (q[page].questions[i].id === question.id) {
        q[page].questions[i].answer = answer;
        try {
          //test
          if (typeof answer === "object") {
            if (f[page] === undefined) f[page] = {};
            f[page][q[page].questions[i].id] = answer;
          } else if (answer === null) {
            f[page][q[page].questions[i].id] = null;
          }
        } catch (e) {
          console.log(e);
        }
        break;
      }
    }
    setFiles(f);
    setQuestions(q);
    setIsVisible(ProcessVisibles(questions[page].questions));
  };

  /**
   * checks validity of all answers
   * @param {*} currentPage
   */
  const isValid = (currentPage) => {
    let valid = true;
    setPasswordMismatch(false);
    questions[currentPage].questions.forEach((q) => {
      if (valid) {
        //check if question has showIf
        //check if question is shown, only perform validation if question is shown
        // - otherwise, set answer to null and return as valid
        let performCheck = true;
        if (q.showIf === undefined || q.showIf === null) {
          //perform normal checks
        } else if (!shouldShowQuestion(q, questions[currentPage].questions)) {
          //check if showIf condition is met, if so perform normal check,
          //otherwise ignore (don't perform checks)
          q.answer = null;
          valid = true;
          performCheck = false;
        }

        if (performCheck) {
          if (q.isValid !== undefined) {
            //override validation rule based on isValid value from the question component
            // gives us flexibility to implement specific validation from within the individual component if necessary
            valid = q.isValid;
          } else if (
            (q.answer === undefined || q.answer === null) &&
            q.required
          ) {
            valid = false;
            return;
          } else if (
            q.required &&
            typeof q.answer === "string" &&
            q.answer.trim() === ""
          ) {
            //check if not empty string
            valid = false;
            return;
          }

          if (q.confirmWith !== undefined) {
            for (var i in questions[currentPage].questions) {
              let comp = questions[currentPage].questions[i];
              if (q.confirmWith === comp.id)
                if (q.answer !== comp.answer) {
                  valid = false;
                  setPasswordMismatch(true);
                  return;
                }
            }
          }
        }

        q.valid = valid;
      }
    });
    return valid;
  };

  const onBack = () => {
    if (page === 0) return;
    setPage(page - 1);
    setValidated(false);
  };

  const currentProgress = () => {
    return ((page + 1) / (questions.length + 1)) * 100;
  };

  /**
   * handle "copyFrom" parameter
   * copies value from other fields
   */
  const processCopyFrom = (questions, allQuestions) => {
    for (let i in questions) {
      if (questions[i].copyFrom !== undefined) {
        let target = questions[i].copyFrom;
        let copyValue = "";
        //find question in copyFrom
        for (let k in allQuestions) {
          if (allQuestions[k].title.trim() === target.pageTitle.trim()) {
            for (let kq in allQuestions[k].questions) {
              //copy values from the copyFrom param
              if (target.id.indexOf(allQuestions[k].questions[kq].id) > -1) {
                //set question value
                let answer = allQuestions[k].questions[kq].answer;
                if (answer === null || answer === undefined) answer = "";
                copyValue += answer + " ";
              }
            }
            break;
          }
        }
        questions[i].answer = copyValue;
      }
    }
    return questions;
  };

  useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions]);

  useEffect(() => {
    if (page !== null && questions[page] !== undefined) {
      setIsVisible(ProcessVisibles(questions[page].questions));
      questions[page].questions = processCopyFrom(
        questions[page].questions,
        questions
      );
    }
  }, [page, questions]);

  if (questions.length === 0)
    return (
      <Container fluid>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <h2>No questions available yet...</h2>
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container>
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col>
          <ProgressBar
            variant="success"
            striped
            animated
            now={currentProgress()}
          />
        </Col>
      </Row>
      <Form
        noValidate
        validated={validated}
        onSubmit={onSubmit}
        className="list-assessment"
      >
        <Row>
          <Col style={{ textAlign: "center" }}>
            <h2 className="mb-4">{questions[page].title}</h2>
          </Col>
        </Row>
        {questions[page].description !== null && (
          <Row>
            <Col style={{ textAlign: "center" }}>
              <div
                className="pq-question-description"
                dangerouslySetInnerHTML={{
                  __html: questions[page].description,
                }}
              ></div>
            </Col>
          </Row>
        )}
        {error !== null && (
          <Row>
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs md></Col>
          <Col xs={12} md={6}>
            {questions[page].questions.map((item, i) => {
              if (isVisible[i])
                return <QuestionItem key={i} data={item} onAnswer={onAnswer} />;
              else return <div key={i}></div>;
            })}
          </Col>
          <Col xs md></Col>
        </Row>
        {props.showLegend && (
          <Row>
            <Col xs md></Col>
            <Col xs={12} md={6} className="text-center">
              <em style={{ fontSize: "0.9em", fontWeight: "bold" }}>
                <span className="text-danger">*</span> required
              </em>
            </Col>
            <Col xs md></Col>
          </Row>
        )}
        <Row>
          <Col style={{ textAlign: "center" }} className="my-4">
            {props.busy ? (
              <Button type="button">
                <Spinner /> Submitting...
              </Button>
            ) : (
              <ButtonGroup>
                {page > 0 && (
                  <Button
                    variant="secondary"
                    className="btn-back"
                    onClick={onBack}
                  >
                    Back
                  </Button>
                )}
                <Button variant="primary" type="submit" className="btn-next">
                  {page < questions.length - 1 ? "Next" : "Submit"}
                </Button>
              </ButtonGroup>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Questions;
