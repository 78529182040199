import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Questions from "./pages/Questions";
import "./App.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Intro from "./pages/Intro";
import Thankyou from "./pages/Thankyou";
import QuestionsValues from "./pages/QuestionsValues.js";
import AssessmentStore from "./store/AssessmentStore";

function App() {
  const [page, setPage] = useState(1); //check the return value for page number assignments
  const [busy, setBusy] = useState(false);
  const [questions, setQuestions] = useState(null);
  let uploadFiles = []; //files

  useEffect(() => {
    setQuestions(QuestionsValues);
  }, []);

  const forward = (params) => {
    setPage(page + 1);
  };

  /**
   * sends the answers along with each questions to the backend for processing
   * @param {JSON} data paged question and answers data
   */
  const onSubmit = (data, files) => {
    uploadFiles.push(files);
    setQuestions(data);
    saveData(data, false);
  };

  const saveData = (answers, combined) => {
    let data = JSON.parse(JSON.stringify(answers));
    for (let i in data) {
      //add check to remove questions not need in "Key Contact" page
      if (data[i].title.toUpperCase() === "KEY CONTACT") {
        if (data[i].questions[0].answer) {
          data[i].questions.splice(1, 3);
        } else {
          data[i].questions.splice(4, 6);
        }
        data[i].questions.splice(0, 1);
      }

      for (let k in data[i].questions) {
        //delete options (not needed to be saved)
        delete data[i].questions[k].options;
      }
    }

    // console.log(data);
    // return;

    let formData = new FormData();
    //fetch all files from questions
    formData.append("data", JSON.stringify(data));
    formData.append("user", parseUser(data));

    for (let index = 0; index < uploadFiles.length; index++) {
      let files = uploadFiles[index];
      for (let fi in files) {
        for (let fk in files[fi]) {
          if (files[fi][fk])
            formData.append("file" + fi + fk, files[fi][fk][0]);
        }
      }
    }

    setBusy(true);
    AssessmentStore.SubmitAnswers(formData, onProgress)
      .then((result) => {
        forward();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  //progress callback for file upload/sending
  const onProgress = (e) => {
    console.log(e);
  };

  //remove extra data, keep: page title, description, questions and answers
  const parseUser = (a) => {
    let data = {};
    a.forEach((page) => {
      page.questions.forEach((q) => {
        if (q.question.toUpperCase() === "FIRST NAME")
          data.first_name = q.answer;
        if (q.question.toUpperCase() === "LAST NAME") data.last_name = q.answer;
        if (q.question.toUpperCase() === "EMAIL") data.email = q.answer;
      });
    });
    return JSON.stringify(data);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {page === 1 && <Intro onForward={forward} />}
          {/* part 1 */}
          {page === 2 && (
            <Questions
              questions={questions}
              busy={busy}
              onSubmit={onSubmit}
              showLegend={true}
            />
          )}

          {/* part 2 */}
          {/* {page === 4 && questions !== null && (
            <Questions
              questions={questions[1]}
              busy={busy}
              onSubmit={onSubmit}
            />
          )} */}
          {page === 3 && <Thankyou />}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
