import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Date = (props) => {
  const updateText = (e) => {
    props.onAnswer(e.target.value);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col>
          <Form.Control
            onChange={updateText}
            type="date"
            required={props.data.required}
            defaultValue={props.data.answer}
            disabled={
              props.data.disabled === undefined ? false : props.data.disabled
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default Date;
