import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as yup from "yup";

const Password = (props) => {
  const [error, setError] = useState(null);
  let schema = yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/,
      "Password needs to consist of at least a capital letter, a number, and a minimum of 8 characters"
    )
    .min(8, "Minimum of 8 characters");

  const updateText = (e) => {
    setError(null);
    let res = "";
    try {
      res = schema.validateSync(e.target.value);
      props.onAnswer(res);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col>
          <Form.Control
            required={props.data.required}
            onChange={updateText}
            type="password"
            defaultValue={props.data.answer}
          />
          {error !== null && (
            <div style={{ color: "red", fontSize: "0.8em", marginTop: "4px" }}>
              {error}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Password;
