import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SingleChoice = (props) => {
  const [other, setOther] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState("---");
  const label_other = "Other...";
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    if (props.data.answer === null || props.data.answer === undefined) {
      setCurrentAnswer("---");
      return;
    }
    let isOther = true;

    for (var i = 0; i < props.data.options.length; i++) {
      if (props.data.answer === label_other) {
        isOther = true;
        break;
      } else if (props.data.options[i] === props.data.answer) {
        isOther = false;
        break;
      }
    }
    setOther(isOther);
    if (isOther) {
      setCurrentAnswer(label_other);
      setOtherValue(props.data.answer === label_other ? "" : props.data.answer);
    } else {
      setOtherValue("");
      setCurrentAnswer(props.data.answer);
    }
  }, [props.data.answer, props.data.options]);

  const handleChange = (e) => {
    setOther(false);
    if (e.target.value === "---") {
      setCurrentAnswer("---");
      props.onAnswer(null);
    } else if (e.target.value === label_other) {
      //show others
      setOther(true);
      setOtherValue("");
      setCurrentAnswer(e.target.value);
      props.onAnswer(e.target.value);
    } else {
      setCurrentAnswer(e.target.value);
      props.onAnswer(e.target.value);
    }
  };

  const updateText = (e) => {
    setOtherValue(e.target.value);
    props.onAnswer(e.target.value);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Form.Control
        id={props.data.id}
        as="select"
        onChange={handleChange}
        value={currentAnswer}
        required={props.data.required}
      >
        <option>---</option>
        {props.data.options.map((item, i) => (
          <option key={i}>{item}</option>
        ))}
        {props.data.hasOthers && (
          <option value={label_other}>{label_other}</option>
        )}
      </Form.Control>
      {other && (
        <Row style={{ marginTop: 10 }}>
          <Col>
            <Form.Control
              onChange={updateText}
              type="text"
              defaultValue={otherValue}
              placeholder="Please provide your answer ..."
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SingleChoice;
