import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

/**
 * handle multiple selection (radio?)
 * @param {*} props
 */
const MultipleChoice = (props) => {
  const [answers, setAnswers] = useState({});

  const handleChange = (e) => {
    let a = answers;
    a[e.target.id] = e.target.checked;
    setAnswers(a);
    a = [];
    for (var i in answers) {
      if (answers[i]) a.push(i);
    }
    props.onAnswer(a.join(","));
  };

  useEffect(() => {
    let a = {};
    for (var i = 0; i < props.data.options.length; i++) {
      a[props.data.options[i]] = false;
    }

    if (props.data.answer) {
      let ans = props.data.answer.split(",");
      for (i = 0; i < ans.length; i++) {
        a[ans[i]] = true;
      }
    }
    setAnswers(a);
  }, [props.data.answer, props.data.options]);

  return (
    <div>
      {props.data.options.map((item, i) => (
        <Form.Check
          key={i}
          label={item}
          type="checkbox"
          id={item}
          defaultChecked={answers[item]}
          onClick={handleChange}
        />
      ))}
    </div>
  );
};

export default MultipleChoice;
