import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as yup from "yup";

const Email = (props) => {
  const [error, setError] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState("");

  let schema = yup
    .string()
    .email("Please provide a valid email address")
    .required("Field can't be blank")
    .nullable(false);

  useEffect(() => {
    if (props.data.answer) setCurrentAnswer(props.data.answer);
    if (props.data.answer === null || props.data.answer === undefined)
      setCurrentAnswer(null);
  }, [props.data.answer]);

  const updateText = (e) => {
    setError(null);
    setCurrentAnswer(e.target.value);
    try {
      schema.validateSync(e.target.value);
      props.data.isValid = true;
    } catch (err) {
      setError(err.message);
      props.data.isValid = false;
    }
    props.onAnswer(e.target.value);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col>
          <Form.Control
            required={props.data.required}
            onChange={updateText}
            type="text"
            value={currentAnswer === null ? "" : currentAnswer}
            disabled={
              props.data.disabled === undefined ? false : props.data.disabled
            }
          />
          {error !== null && (
            <div style={{ color: "red", fontSize: "0.8em", marginTop: "4px" }}>
              {error}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Email;
