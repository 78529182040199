import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/**
 *validate number value based on the format provided
 * @param {String} v Value entered by the user
 * @param {String} format [null|undefined|MONEY]
 */
const validate = (v, format) => {
  let patMoney = /(?=.*\d)^(([1-9]\d{0,2}(,\d{3})*)|0)?(\.\d{1,2})?$/; //currency/decimals
  let patNumber = /^\d+$/; //whole number

  if (format === undefined) return patNumber.test(v);
  switch (format) {
    case "MONEY":
      return patMoney.test(v);
    default:
      return v;
  }
};

const Number = (props) => {
  const [error, setError] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState("");

  useEffect(() => {
    if (props.data.answer) {
      if (validate(props.data.answer, props.data.format))
        setCurrentAnswer(props.data.answer);
    } else {
      setCurrentAnswer("");
    }
  }, [props.data.answer, props.data.format]);

  const updateText = (e) => {
    setError(null);
    let ans = format(e.target.value);
    setCurrentAnswer(ans);
    if (validate(ans, props.data.format)) {
      props.onAnswer(ans);
    } else {
      setError("Please enter a valid number value.");
    }
  };

  const format = (v) => {
    let answer = v.replaceAll(",", "");
    return answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const onLeave = (e) => {
    if (!validate(currentAnswer, props.data.format)) props.onAnswer(null);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col>
          <Form.Control
            onChange={updateText}
            type="text"
            required={props.data.required}
            value={currentAnswer}
            onBlur={onLeave}
            disabled={
              props.data.disabled === undefined ? false : props.data.disabled
            }
          />
          {error !== null && (
            <div style={{ color: "red", fontSize: "0.8em", marginTop: "4px" }}>
              {error}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Number;
