import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const TextArea = (props) => {
  const [count, setCount] = useState(0);
  const [hasCount, setHasCount] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState("");

  useEffect(() => {
    if (props.data.maxLength !== undefined) {
      setHasCount(true);
      let c =
        props.data.maxLength -
        (props.data.answer ? props.data.answer.length : 0);
      setCount(c);
    }
  }, [props.data.answer, props.data.maxLength]);

  const updateText = (e) => {
    setCurrentAnswer(e.target.value);
    props.onAnswer(e.target.value);
  };

  const keyUp = (e) => {
    setCount(props.data.maxLength - currentAnswer.length);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col>
          <Form.Control
            required={props.data.required}
            onChange={updateText}
            onKeyUp={keyUp}
            as="textarea"
            rows={5}
            maxLength={props.data.maxLength}
            defaultValue={props.data.answer}
          />

          {hasCount && (
            <div
              className="text-right"
              style={{ fontSize: "0.8em", marginTop: 5 }}
            >
              <strong>{count}</strong> characters remaining
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TextArea;
