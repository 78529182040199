import axiosInstance from "./AxiosInstance";

const SaveUser = (info) => {
  return axiosInstance.post("/assessment/user", info);
};

const FetchUser = (info) => {
  return axiosInstance.get("/assessment/user", info);
};

const LoadQuestions = () => {
  return axiosInstance.get("/assessment/questions");
};

const SubmitAnswers = (info, onProgress) => {
  //allow uploading of files
  return axiosInstance.post("/assessment/submit", info, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const ConfirmUser = (code, info) => {
  return axiosInstance.get("/assessment/confirm/" + code, { params: info });
};

const AssessmentStore = {
  SaveUser,
  FetchUser,
  LoadQuestions,
  SubmitAnswers,
  ConfirmUser,
};

export default AssessmentStore;
