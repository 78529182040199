import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

/**
 * accept file for uploading later
 * @param {*} props
 */
const FileUpload = (props) => {
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const mime = {
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xls: "application/vnd.ms-excel",
    docx:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    doc: "application/msword",
    pdf: "application/pdf",
    txt: "text/plain",
    csv: "text/csv",
    png: ["image/png", "image/x-citrix-png", "image/x-png"],
    jpg: ["image/jpeg", "image/x-citrix-jpeg"],
    jpeg: ["image/jpeg", "image/x-citrix-jpeg"],
    gif: "image/gif",
    bmp: "image/bmp",
    tiff: "image/tiff",
  };

  useEffect(() => {
    if (props.data.answer !== undefined) setFile(props.data.answer);
  }, [props.data.answer]);

  const handleChange = (e) => {
    setError(null);
    let isValid = false;
    //only perform file type validation if "allowed" field is set. (should be an array of file types)
    if (props.data.allowed) {
      for (var i = 0; i < props.data.allowed.length; i++) {
        let fileType = mime[props.data.allowed[i]];
        if (fileType !== null && e.target.files[0].type === fileType) {
          isValid = true;
          break;
        }
      }
      if (!isValid) {
        setError("File type not allowed, please choose another file");
        setFile(null);
        props.onAnswer(null);
        return;
      }
    }
    //add validation of file types here
    setFile(e.target.files);
    props.onAnswer(e.target.files);
  };

  const onClear = (e) => {
    setError(null);
    setFile(null);
    props.onAnswer(null);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col>
          {file === null && (
            <React.Fragment>
              <Form.File onChange={handleChange} custom label="Choose File" />
              {props.data.allowed !== null && props.data.allowed && (
                <div>
                  <em>File types allowed: {props.data.allowed.join(", ")}</em>
                </div>
              )}
            </React.Fragment>
          )}
          {file !== null && (
            <React.Fragment>
              <div>
                <Form.Control readOnly defaultValue={file[0].name} />
              </div>
              <Button variant="secondary" onClick={onClear}>
                Clear
              </Button>
            </React.Fragment>
          )}
          {error !== null && (
            <div style={{ color: "red", fontSize: "0.8em", marginTop: "4px" }}>
              {error}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FileUpload;
