import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SingleChoice from "./SingleChoice";
import Date from "./Date";
import Text from "./Text";
import Number from "./Number";
import Email from "./Email";
import TextArea from "./Textarea";
import Password from "./Password";
import Year from "./Year";
import Month from "./Month";
import FileUpload from "./File";
import MultipleChoice from "./MultipleChoice";
import Checkbox from "./Checkbox";

// handle validation and display of error per field
const QuestionItem = (props) => {
  const fieldWidth =
    props.data.question.trim() === "" ? (props.data.required ? 11 : 12) : 6;

  const onAnswer = (answer) => {
    if (props.data.required && typeof answer === "object" && answer === null) {
      answer = null;
    } else if (
      props.data.required &&
      typeof answer === "string" &&
      (answer === null || answer.trim().length === 0)
    ) {
      // setError("Please provide a valid answer");
      answer = null;
    }
    props.onAnswer(props.data, answer);
  };

  return (
    <React.Fragment>
      <Form.Group as={Row}>
        <Form.Label column style={{ fontWeight: "bold" }}>
          <React.Fragment>
            {props.data.question}
            {props.data.required ? <span className="text-danger">*</span> : ""}
          </React.Fragment>
        </Form.Label>
        <Col sm={12} md={fieldWidth}>
          {props.data.type === "SINGLE" && (
            <SingleChoice
              key={props.data.id}
              data={props.data}
              onAnswer={onAnswer}
            />
          )}
          {props.data.type === "DATE" && (
            <Date data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "TEXT" && (
            <Text key={props.data.id} data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "NUMBER" && (
            <Number data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "EMAIL" && (
            <Email data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "TEXTAREA" && (
            <TextArea data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "PASSWORD" && (
            <Password data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "MULTIPLE" && (
            <MultipleChoice data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "YEAR" && (
            <Year data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "MONTH" && (
            <Month data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "CHECKBOX" && (
            <Checkbox data={props.data} onAnswer={onAnswer} />
          )}
          {props.data.type === "FILE" && (
            <FileUpload data={props.data} onAnswer={onAnswer} />
          )}

          <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Row>
        <Col>
          <div
            style={{
              borderBottom: "1px dashed #ddd",
              height: 1,
              marginBottom: 10,
            }}
          ></div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default QuestionItem;
