import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
//import Image from "react-bootstrap/Image";
//import stock_image from "../assets/images/stock-capabilites.jpg";
//import our_platform from "../assets/images/our-platform.jpg";

const Intro = (props) => {
  const onNext = () => {
    props.onForward();
  };

  return (
    <Container>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h2 className="mt-5">
            A quick note as you begin your assessment <br />
            <em style={{ fontSize: "0.7em", textDecoration: "italic" }}>
              (Completion time: ∼5 mins or less)
            </em>
          </h2>
        </Col>
      </Row>
      <Row style={{ margin: "20px" }}>
        <Col>
          <p>
            Typically issuers looking to list on a stock exchange have assets
            backing their securities or have some traction beyond an idea.
          </p>
          <p>
            Issuers also have enough runway to get their securities listed and
            some time beyond as well as they will need to maintain the ongoing
            disclosure requirements and obligations for their active listings.
          </p>
          <p>
            Listing costs for the exchange and listing sponsor typically
            includes cash and equity components.
          </p>
          <p>
            Specific costs/terms will be different for each security depending
            on various factors we can discuss.
          </p>
          <p>
            There are potential volume savings for "serial" issuers looking to
            list several assets.
          </p>
          <p>
            Note we can support your ongoing obligations and disclosure
            requirements through our software solution to mitigate some of the
            administrative burden as well as support your investors through our
            portal and trading expertise.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={12} xs="auto">
          <h1
            style={{ color: "#5592db", textAlign: "center", fontWeight: 700 }}
          >
            Our Platform
          </h1>
          <h3 style={{ color: "#474747", textAlign: "center" }}>
            A complete secondary trading solution made simple
          </h3>
        </Col>{" "}
      </Row>
      <br />
      <Row className="justify-content-md-center">
        <Col md={5} xs="12">
          <div
            style={{
              color: "#8cb17d",
              border: "#8cb17d 2px solid",
              textAlign: "center",
              fontSize: "1.3rem",
            }}
          >
            <b> LIST</b>
          </div>
          <br />
          <Row className="justify-content-md-left">
            <Col md={6} xs="12">
              <div
                style={{
                  color: "#5592db",
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                <h6>
                  <b> Get listed quickly</b>{" "}
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                <h6>
                  {" "}
                  <b> Ring the bell</b>
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                We lead the listing process and support fulfilling your ongoing
                obligations as your Sponsor Advisor
              </div>
              <br />
              <hr />
              <div>
                <i>
                  Traditional and <br /> Digital Securities{" "}
                </i>
              </div>
              <br />
            </Col>

            <Col md={6} xs="12">
              <div
                style={{
                  color: "#5592db",
                  textAlign: "left",
                }}
              >
                <h6>
                  <b> Reporting made easy</b>
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                <h6>
                  <b> Compliance handled</b>
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                It's like having your own team, without the hassles, to handle
                ongoing disclosure requirements as a reporting company
              </div>
              <hr />
              <div>
                <i>
                  Focus On Your Business, <br /> While Ensuring Compliance
                </i>
              </div>
              <br />
            </Col>
          </Row>
        </Col>
        <Col md={1} xs="12"></Col>
        <Col md={5} xs="12">
          <div
            style={{
              color: "#8cb17d",
              border: "#8cb17d 2px solid",
              textAlign: "center",
              fontSize: "1.3rem",
            }}
          >
            <b> TRADE</b>
          </div>
          <br />
          <Row className="justify-content-md-left">
            <Col md={6} xs="12">
              <div
                style={{
                  color: "#5592db",
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                <h6>
                  <b> Own the experience</b>{" "}
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                <h6>
                  <b> Keep it one-on-one</b>
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                Create an investor experience as if you had your own branded
                version of a regulated stock exchange
              </div>
              <br />
              <hr />
              <div>
                <i>
                  Keep it Simple and <br /> Protect Your Brand
                </i>
              </div>
              <br />
            </Col>

            <Col md={6} xs="12">
              <div
                style={{
                  color: "#5592db",
                  textAlign: "left",
                }}
              >
                <h6>
                  <b> Support trading</b>
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                <h6>
                  <b> Ensure liquidity</b>
                </h6>
              </div>{" "}
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                Access investment banking-style advisory and professional
                trading, with global strategies including cross-listings
              </div>
              <br />
              <hr />
              <div>
                <i>
                  Fiat and Crypto <br /> Account Funding
                </i>
              </div>
              <br />
            </Col>
          </Row>
        </Col>
      </Row>
      {/*  <Row className="justify-content-md-center">
        <Col md={10} xs="auto">
          <Image src={our_platform} width="100%" />
        </Col>
      </Row>*/}
      <Row>
        <Col
          style={{ textAlign: "center", marginTop: "15px" }}
          className="mb-4"
        >
          <Button onClick={onNext} className="btn-start">
            START
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Intro;
